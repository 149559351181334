import axios from 'axios';

const API_BASE_URL = 'https://threearrowstech.com/projects/gdsgdemo/admin_apis';

//staging
//const API_BASE_URL = 'https://threearrowstech.com/projects/gdsg/staging/admin_apis';
// admin apis
// dashboard
export  function dashboard(callback, errorCallback) {
  axios.post(`${API_BASE_URL}${'/admin/dashboard/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
    
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
// dashboard transactions
export  function dashboardtransactions(callback, errorCallback) {
  axios.post(`${API_BASE_URL}${'/admin/lasttransactions/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
    
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//donation summary
export  function donationsummary(callback, errorCallback) {
  axios.post(`${API_BASE_URL}${'/admin/donationsummary/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


//create user session
export  function createusersession(params,callback, errorCallback) {
  axios.post(`${API_BASE_URL}${'/api/createusersession/'}`, params, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


//downgrade non complaint
export  function downgradenoncomplaintuser(params,callback, errorCallback) {
  axios.post(`${API_BASE_URL}${'/api/downgradenoncomplaintuser/'}`, params, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


//delete non complaint
export  function deletenoncomplaintuser(params,callback, errorCallback) {
  axios.post(`${API_BASE_URL}${'/api/deletenoncomplaintuser/'}`, params, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//payout summary

export  function payoutsummary(callback, errorCallback) {
  axios.post(`${API_BASE_URL}${'/admin/payoutsummary/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function deployuser(params,callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/deployuser/'}`, params
  , {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function deploymanualpaymentuser(params,callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/deploymanualpaymentuser/'}`, params
  , {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}




export  function rejectmanualpaymentuser(params,callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/rejectmanualpaymentuser/'}`, params
  , {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



//Get Users List

export  function getusers(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/admin/getuserslist/', params,{
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



//get manual payments data

export  function getmanualpayments(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/admin/getmanualpayments/', params,{
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Delete Pending user

export  function deleteuser(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/admin/deleteuser/', params,{
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


//Create Admin

export  function createadmin(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/admin/makeadmin/', params,{
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


//Get Admins

export  function getadmins(callback, errorCallback) {
  axios.post(`${API_BASE_URL}${'/admin/getadminslist/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


//Remove  Mini Admin
export  function removeadmin(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/admin/removeadmin/', params,{
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Update Sponsor 
export  function updatesponsor(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/admin/updatesponsor/', params,{
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}






export function registerUser(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/admin/user/register/', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function authenticate(service, callback, errorCallback) {
  axios.post(API_BASE_URL, service)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function  authUserData(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/user/userdata/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
    
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function roidata(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/user/roidata/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
    
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function lasttransactions(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/lastweektransactions/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
    
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function referralusers(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/user/referralusers/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function investmentreport(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/user/depositsummary/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function referralbonusreport(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/referralbonussummary/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}





export  function unilevelbonusreport(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/unilevelbonussummary/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export  function faqdata(callback, errorCallback) {
  axios.post(`${API_BASE_URL}${'/admin/getfaqs/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function transaction(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/admin/transaction/', params,{
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function invitation(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/admin/sendinvitationlink/', params,{
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export  function adminwallet(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/getadminwallet/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function getHierarchyData(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/gethierarchy/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export  function getBinaryTreeData(userrandomcode,callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/getbinarytree/'}`, {
    userrandomcode:userrandomcode
  }, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function updateProfileData(formdata, callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/updateprofiledata/'}`, formdata, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function verifyUserEmailManual(params, callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/verifyuseremailmanual/'}`, params, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function updateProfilePicture(formdata, callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/updateprofilepicture/'}`, formdata, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function updateProfilePassword(oldpassword,newpassword, callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/updatepassword/'}`, {
    oldpassword,
    newpassword
  }, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function requestPasswordReset(email, callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/forgetpassword/'}`, {
    email
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export  function investandeearning(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/monthlyinvestandeearning/'}`, '', {
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export  function getnotifications(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/getnotifications/'}`, '', {
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function updatenotificationstatus(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/updatenotificationstatus/'}`, '', {
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function passwordReset(email,password, callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/resetpassword/'}`, {
    email,
    password
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function validateEmailToken(token,email, callback,errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/validateemailtoken/'}`, {
    token,
    email
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function verifyemailaccount(token,email, callback,errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/verifyemailaccount/'}`, {
    token,
    email
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

