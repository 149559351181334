import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { payoutsummary } from 'backendServices/ApiCalls';
import { Chip } from '@mui/material';
import { getBgColorStyle } from '@jumbo/utils';
import { ApprovalRounded } from '@mui/icons-material';


const columns = [
  {
    field: "sender",
    headerName: "Sender Username",  
    dataGeneratorUniquenessEnabled: true,
    width: 150,
    editable: true,
    groupable: false,
    aggregable: false,
    aggregable: false,
    renderCell: (params) => {
      const senderUsername = params?.row?.senderusername;
      return <span>{senderUsername || 'Company'}</span>;
    }
},
{
  field: "receiver",
  headerName: "Receiver Username",  
  dataGeneratorUniquenessEnabled: true,
  width: 150,
  editable: true,
  groupable: false,
  aggregable: false,
  aggregable: false,
  renderCell: (params) => {
    const receiverusername = params?.row?.receiverusername;
    return <span>{receiverusername}</span>;
  }

},

  {
      field: "amount",
      headerName: "Amount",  
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false, 
      renderCell: (params) => `$${params.value}`
 
  },
  {
    field: "details",
    headerName: "Details",  
    dataGeneratorUniquenessEnabled: true,
    width: 300,
    editable: true,
    groupable: false,
    aggregable: false, 
    renderCell: (params) => {
      const details = params?.row?.details;
      const receiverusername = params?.row?.receiverusername; // Assuming the username is stored in the variable 'username'
      const replacedString = details.replace("you", receiverusername);
      // In your React component, you can render the replaced string
      return <div>{replacedString}</div>;
    } 
},
  {
      field: "createdat",
      headerName: "Date",  
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,    
  },
  {
    field: "status",
    headerName: "Status",  
    dataGeneratorUniquenessEnabled: true,
    width: 200,
    editable: true,
    groupable: false,
    aggregable: false,  
    renderCell: (params) => {
      const status = params?.row?.status;
      if(status==='pending'){
        return <Chip label={status} color={'warning'} size={'small'}/>
      }
      if(status==='approved'){
        return <Chip label={status} color={'success'} size={'small'}/>
      }
      ;
    } 

}

]

const initialState= {initialState:{
  columns:{
      columnVisibilityModel:{
        id: false,
        avatar: false,
        website: false,
        email: false,
        phone: false,
        username: false,
        city: false,
        company: false,
        position: false,
        lastUpdated: false,
        salary: false,
    }
}
}
}


const VISIBLE_FIELDS = ['sr', 'amount', 'status','date'];
const PayoutReports = () => {
  
  const [payoutdata,setPayoutData]=useState([])
  const PayoutData =()=>{
    payoutsummary((response) => {
      if(response?.data?.status === "success") {
          setPayoutData(response?.data?.data?.enteries)
      }
      }, (error) => {
          console.log(error?.response?.data); 
      })
  }
  console.log("payoutdata",payoutdata)
    
useEffect(()=>{
    console.log('useeffect')
    PayoutData();
},[])


const rows= payoutdata
const gridDesign = {
  '& .MuiDataGrid-toolbarContainer': {
    '& .MuiButton-text': {
      fontSize: '13px !important',
      color: '#000',
    },
    '& .MuiBadge-badge': {
      backgroundColor: '#074682',
    },
    '& .MuiInput-root':{
      borderRadius: 2,
      paddingLeft: 2,
      overflow: 'hidden',
    },

  }
}

  return (
    <JumboDemoCard
    title={"Payout Summary"}
    wrapperSx={{backgroundColor: 'background.paper', pt: 0}}
>

    <Box sx={{ height: 400, width: 1 }}>
    <DataGrid
        initialState={{
          initialState,
          pagination: { paginationModel: { pageSize: 6 } },
        }}
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        sx={gridDesign}
        pageSizeOptions={[6, 12, 18, 24, 30]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  </JumboDemoCard>
  )
}

export default PayoutReports