import React from "react";

import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import LogoutIcon from '@mui/icons-material/Logout';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PasswordIcon from '@mui/icons-material/Password';
import GroupIcon from '@mui/icons-material/Group';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PaymentsIcon from '@mui/icons-material/Payments';
const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
        children: [
            {
                uri: "/dashboard",
                label: 'sidebar.menuItem.dashboard',
                type: "nav-item",
                icon: <GraphicEqIcon sx={{fontSize: 20}}/>
            },
           
            {
                uri: "/create-admin",
                label: 'sidebar.menuItem.createadmin',
                type: "nav-item",
                icon: <AdminPanelSettingsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/manage-admin",
                label: 'sidebar.menuItem.manageadmin',
                type: "nav-item",
                icon: <AdminPanelSettingsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/active-users",
                label: 'sidebar.menuItem.activeusers',
                type: "nav-item",
                icon: <GroupIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/inactive-users",
                label: 'sidebar.menuItem.inactiveusers',
                type: "nav-item",
                icon: <GroupIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/non-compliant-users",
                label: 'sidebar.menuItem.noncompliantusers',
                type: "nav-item",
                icon: <GroupIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/downgrade-list",
                label: 'sidebar.menuItem.downgrade',
                type: "nav-item",
                icon: <GroupIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/pending-manual-payments",
                label: 'sidebar.menuItem.pendingpayments',
                type: "nav-item",
                icon: <PaymentsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/approved-manual-payments",
                label: 'sidebar.menuItem.approvedpayments',
                type: "nav-item",
                icon: <PaymentsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/rejected-manual-payments",
                label: 'sidebar.menuItem.rejectedpayments',
                type: "nav-item",
                icon: <PaymentsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/change-sponsor",
                label: 'sidebar.menuItem.changesponsor',
                type: "nav-item",
                icon: <ManageAccountsIcon sx={{fontSize: 20}}/>
            },

            {
                uri: "/donation-summary",
                label: 'sidebar.menuItem.donationsummary',
                type: "nav-item",
                icon: <SummarizeIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/payout-summary",
                label: 'sidebar.menuItem.payoutsummary',
                type: "nav-item",
                icon: <SummarizeIcon sx={{fontSize: 20}}/>
            },
           
          
         
            // {
            //     uri: "/payment-method",
            //     label: 'sidebar.menuItem.updatepayment',
            //     type: "nav-item",
            //     icon: <AccountBalanceIcon sx={{fontSize: 20}}/>
            // },
      

            {
                uri: "/profile",
                label: 'sidebar.menuItem.updatepassword',
                type: "nav-item",
                icon: <PasswordIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/logout",
                label: 'sidebar.menuItem.logout',
                type: "nav-item",
                icon: <LogoutIcon sx={{fontSize: 20}}/>
            },

            
        ]
    },
  
];

export default menus;
